import React, { Component, useState, navigate } from "react"
import { Highlight, connectAutoComplete } from "react-instantsearch-dom"
import AutoSuggest from "react-autosuggest"
import theme from "./autocomplete.module.css"
import { Link } from "gatsby"
import algoliasearch from "algoliasearch/lite"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

const alogliaIndexName = process.env.GATSBY_ALGOLIA_INDEX_NAME || "Products"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)
const index = searchClient.initIndex(alogliaIndexName)

class Autocomplete extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.currentRefinement,
      categories: null,
      manufacturers: null,
      suggestionHighlighted: false,
    }
  }

  onChange = (event, { newValue, method }) => {
    if (!newValue) {
      this.props.onSuggestionCleared()
    }

    this.setState({ value: newValue })
  }

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (!this.state.suggestionHighlighted)
        this.props.onEnterPressed(this.state.value)
      event.target.blur()
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value)
    index
      .searchForFacetValues("categories.lvl1", value)
      .then(({ facetHits }) => {
        //console.log(facetHits);
        let categories = facetHits.map((hit) => ({ ...hit, type: "category" }))
        this.setState({ categories: categories })
      })
    index
      .searchForFacetValues("manufacturerName", value)
      .then(({ facetHits }) => {
        let manufacturers = facetHits.map((hit) => ({
          ...hit,
          type: "manufacturer",
        }))
        //console.log(manufacturers);
        this.setState({ manufacturers: manufacturers })
      })
  }

  onSuggestionsClearRequested = () => {
    this.props.refine()
  }

  getSuggestionValue(hit) {
    if (hit.type) return hit.value
    return hit.name
  }

  renderSuggestion(hit) {
    if (hit.value) {
      return (
        <div className={theme.categoryItem}>
          <span dangerouslySetInnerHTML={{ __html: hit.highlighted }} />{" "}
          <b>({hit.count})</b>
        </div>
      )
    } else {
      return (
        <div>
          <span className={theme.thumbnail}>
            <img
              alt={`${hit.productCode} / ${hit.manufacturerName}`}
              className={theme.thumbnailImage}
              src={hit.primaryPhoto}
            />
          </span>
          <span className={theme.header}>
            <Highlight attribute="name" hit={hit} tagName="mark" />
          </span>
          <span className={theme.detail}>
            {hit.productCode} / {hit.manufacturerName}
          </span>
        </div>
      )
    }
  }

  renderSectionTitle(section) {
    return <span className={theme.sectionTitle}>{section.title}</span>
  }
  getSectionSuggestions(section) {
    return section.results
  }

  onSuggestionHighlighted = ({ suggestion }) => {
    console.log(`Highlighted: ${suggestion}`)
    var newValue = suggestion ? true : false
    if (this.state.suggestionHighlighted != newValue)
      this.setState({ suggestionHighlighted: newValue })
  }

  renderInputComponent = (inputProps) => {
    return (
      <div
        className="border-charcoal border-[1px] rounded-[2px] hover:border-scarlet-red focus-within:border-scarlet-red p-2 bg-white"
        style={{ display: "flex", alignItems: "center" }}
      >
        <FontAwesomeIcon
          icon={faSearch}
          className="w-4 text-charcoal"
          // size="2x"
          onClick={() => this.props.onEnterPressed(this.state.value)}
        />
        <input
          className="pl-4 outline-none w-full placeholder-medium-gray border-none bg-white"
          {...inputProps}
          style={{ flexGrow: 2 }}
        />
      </div>
    )
  }

  render() {
    const { hits, onSuggestionSelected } = this.props
    const { value } = this.state

    const inputProps = {
      placeholder: "Search product name, number, or keyword",
      onChange: this.onChange,
      value,
      onKeyDown: this.onKeyDown,
      ref: this.textInput,
    }

    let combined = []
    if (this.state.categories && this.state.categories.length > 0)
      combined.push({
        title: "CATEGORIES",
        results: [...this.state.categories],
      })
    if (this.state.manufacturers && this.state.manufacturers.length > 0)
      combined.push({
        title: "MANUFACTURERS",
        results: [...this.state.manufacturers],
      })
    if (hits) combined.push({ title: "PRODUCTS", results: [...hits] })

    return (
      <AutoSuggest
        multiSection={true}
        suggestions={combined}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
        renderSectionTitle={this.renderSectionTitle}
        getSectionSuggestions={this.getSectionSuggestions}
        theme={theme}
        renderInputComponent={this.renderInputComponent}
        suggestion={this.state.suggestion}
        onSuggestionHighlighted={this.onSuggestionHighlighted}
      />
    )
  }
}

export default connectAutoComplete(Autocomplete)
