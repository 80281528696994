import React from "react"
import LOGO from "../../images/logos/SSLLC Logo_Solid_White.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLinkedinIn,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer className="bg-red text-white">
      <div className="container max-w-screen-xl py-10">
        <div className="flex flex-wrap mt-4">
          {/* Left Column - Company Info */}
          <div className="w-full md:w-2/5 lg:w-1/2 flex items-center justify-between md:justify-start md:text-left pb-6 px-3">
            <div className="flex flex-wrap w-full">
              <div className="w-full">
                <img
                  src={LOGO}
                  alt="Surplus Solutions Logo in all white"
                  width="180"
                  className=""
                />
              </div>
              <div className="w-full text-left pb-4 md:pb-0">
                <div className="pt-3 pb-2">
                  <p>
                    2010 Diamond Hill Road
                    <br />
                    Woonsocket, RI 02895
                    <br />
                    <a
                      className="text-white hover:text-white font-medium"
                      href="tel:+14015260055"
                    >
                      401-526-0055
                    </a>
                  </p>
                </div>
                <div className="py-4">
                  <Link
                    to="/contact-us"
                    className="bg-white text-[1.2rem] text-red px-4 py-2 hover:border-[2px] rounded-[4px] hover:bg-red hover:text-white transition-all duration-100 ease-in-out"
                  >
                    Contact Us
                  </Link>
                </div>
                <div className="text-xl space-x-5 md:justify-start justify-end md:w-1/2 w-full">
                  <a
                    href="https://x.com/SurplusSolution"
                    className="text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faXTwitter} />
                  </a>
                  <a
                    href="https://www.youtube.com/user/SurplusSolutionsLLC"
                    className="text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/surplus-solutions-llc/"
                    className="text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="w-full md:w-3/5 lg:w-1/2 grid grid-cols-2 md:grid-cols-3">
            {/* Middle Column - Explore */}
            <div className="w-full text-left mb-4 md:mb-5 px-3">
              <h5 className="pb-5 mb-0">Explore</h5>
              <ul className="p-0">
                <li className="mt-0 mb-4 leading-4">
                  <Link
                    to="/buy-equipment/"
                    className="text-white hover:text-white"
                  >
                    Buy Equipment
                  </Link>
                </li>
                <li className="mt-0 mb-4 leading-4">
                  <Link
                    to="/sell-equipment/"
                    className="text-white hover:text-white"
                  >
                    Sell Equipment
                  </Link>
                </li>
                <li className="mt-0 mb-4 leading-4">
                  <Link to="/auctions/" className="text-white hover:text-white">
                    Auctions
                  </Link>
                </li>
                <li className="mt-0 mb-4 leading-4">
                  <Link
                    to="/our-services/"
                    className="text-white hover:text-white"
                  >
                    Services
                  </Link>
                </li>
              </ul>
            </div>

            {/* Middle Column - About */}
            <div className="w-full text-left mb-4 md:mb-5 px-3">
              <h5 className="pb-5 mb-0">About</h5>
              <ul className="p-0 leading">
                <li className="mt-0 mb-4 leading-4">
                  <Link to="/about-us/" className="text-white hover:text-white">
                    About Us
                  </Link>
                </li>
                <li className="mt-0 mb-4 leading-4">
                  <Link
                    to="/about-us/partners/"
                    className="text-white hover:text-white"
                  >
                    Partners
                  </Link>
                </li>
                <li className="mt-0 mb-4 leading-4">
                  <Link
                    to="/learning-center/news/"
                    className="text-white hover:text-white"
                  >
                    News
                  </Link>
                </li>
              </ul>
            </div>

            {/* Right Column - Learning Center */}
            <div className="w-full text-left mb-4 md:mb-5 px-3">
              <h5 className="pb-5 mb-0">Learning Center</h5>
              <ul className="p-0">
                <li className="mt-0 mb-4 leading-4">
                  <Link
                    to="/learning-center/blog/"
                    className="text-white hover:text-white"
                  >
                    Blog
                  </Link>
                </li>
                <li className="mt-0 mb-4 leading-4">
                  <Link
                    to="/faq/"
                    className="text-white hover:text-white"
                  >
                    FAQs
                  </Link>
                </li>
              </ul>
            </div>

            {/* Legal */}
            <div className="md:hidden w-full text-left mb-4 md:mb-5 px-3">
              <h5 className="pb-5 mb-0">Legal</h5>
              <ul className="p-0">
                <li className="mt-0 mb-4 leading-4">
                  <Link
                    to="/privacy-policy/"
                    className="text-white hover:text-white"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="mt-0 mb-4 leading-4">
                  <Link
                    to="/terms-of-use/"
                    className="text-white hover:text-white"
                  >
                    Terms of Use
                  </Link>
                </li>
                <li className="mt-0 mb-4 leading-4">
                  <Link
                    to="/auction-terms-of-use/"
                    className="text-white hover:text-white"
                  >
                    Auction Terms of Use
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Bottom Footer */}
        <div className="hidden md:flex justify-center w-full text-center mt-3 space-x-2">
          <p>
            <Link
              to="/privacy-policy/"
              className="font-light text-white hover:text-white"
            >
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link
              to="/terms-of-use/"
              className="font-light text-white hover:text-white"
            >
              Terms of Use
            </Link>{" "}
            |{" "}
            <Link
              to="/auction-terms-of-use/"
              className="font-light text-white hover:text-white"
            >
              Auction Terms of Use
            </Link>
          </p>
        </div>
        <div className="text-center">
          <p className="mt-2">
            © 2025 Surplus Solutions, LLC. All rights reserved
          </p>
        </div>
      </div>
    </footer>
  )
}
