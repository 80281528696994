import React, { useState, useEffect } from "react"
import { animateScroll } from "react-scroll"

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [lastScrollY, setLastScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      // Show the button if scrolling down and user has scrolled more than 200px
      if (currentScrollY > 200 && currentScrollY > lastScrollY) {
        setIsVisible(true)
      }
      // Hide the button if scrolling up
      else if (currentScrollY < lastScrollY) {
        setIsVisible(false)
      }

      setLastScrollY(currentScrollY) // Update the last scroll position
    }

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [lastScrollY])

  return (
    <div
      className={`fixed bottom-0 right-16 z-50 transition-opacity duration-700 ease-in-out ${
        isVisible
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      }`}
    >
      <button
        className="flex items-center justify-center w-10 h-8 rounded-t-md bg-blue text-white hover:bg-royal-blue shadow-md"
        onClick={() => animateScroll.scrollToTop()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 15l7-7 7 7"
          />
        </svg>
      </button>
    </div>
  )
}

export default BackToTop
